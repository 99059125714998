import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import TableData from '../../custom/Table/TableData';
import CellType from '../../../constants/type';
import useI18n from '../../../hooks/useI18n';
import useUserInfo from '../../../hooks/useUserInfo';
import { basketState } from '../../../state/basketState';
import { updateBasketState } from '../../../state/updateBasketState';
import { useRecoilState } from "recoil";
import { Alert, Divider, TextField } from '@mui/material';
import ScaleIcon from '@mui/icons-material/Scale';
import WbIridescentIcon from '@mui/icons-material/WbIridescent';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import basketService from '../../../api/basket';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

function BasketDialog({ handleClickOpen, handleClose, open }) {
    const { lang, l } = useI18n();
    //const [basket, setBasket] = React.useState([]);
    const [basketRecoil, setBasketRecoil] = useRecoilState(basketState);
    const [update, setUpdate] = useRecoilState(updateBasketState);
    const { user } = useUserInfo();
    const config = require('../../../config');    

    React.useEffect(() => {

        const GetBasket = async () => {
            try {

                const basket = await basketService.findAllByUserId(user.CODE, lang);
                setBasketRecoil({basket});
            } catch (error) {
                console.error('Error findAllByUserId >> ', error);

            }
        };

        if(user) GetBasket();

    },[user]);

    React.useEffect(() => {

        const GetBasket = async () => {
            try {

                const basket = await basketService.findAllByUserId(user.CODE, lang);
                setBasketRecoil({basket});
            } catch (error) {
                console.error('Error findAllByUserId >> ', error);

            }
        };

        if(user && open) GetBasket();

    },[user, open]);

    const handleDelete = () => {

        const fetcData = async () => {

            try {
                await basketService.deleteBasket(basketRecoil?.basket); 
                clearBasket();
            } catch (error) {
                console.error('Error deleteBasket >> ',error);
            }
        };
        
        fetcData();
    };

    const handleConfirm = () => {

        const fetcData = async () => {

            try {
                await basketService.confirmBasket(basketRecoil.basket); 
                clearBasket();  
            } catch (error) {
                console.error('Error deleteBasket >> ',error);
            }
        };
        
        fetcData();
    };

    const clearBasket = () => {
        const newBasket = {
            basket: {},
        };
        setBasketRecoil(newBasket);
    };

    const columns = [
        { id: 'PRODUCT', label: l('Code'), minWidth: 150, type: CellType.STRING },
        { id: 'FNAME', label: l('Name'), minWidth: 200, type: CellType.PRODUCT_NAME },
        { id: 'FIMG', label: l('Image'), minWidth: 80, type: CellType.IMG },
        { id: 'FPRICE1', label: l('Price'), minWidth: 100, type: CellType.PRISE_WITH_DISCOUNT },
        { id: 'QTY', label: l('Quantity'), minWidth: 80, type: CellType.EDITABLE_NUMBER },
        { id: 'ITEMSUM', label: l('Sum'), minWidth: 80, type: CellType.PRODUCT_SUM },
    ];

    const handelAction = (event, type, row) => {

        const { value } = event.target;

        switch (type) {
            case CellType.EDITABLE_NUMBER:
                if (user) {
                    try {

                        const fetcData = async () => {

                            const basket = await basketService.updateBasket(row);
                            setBasketRecoil({ basket })
                        };

                        fetcData();

                    } catch (error) {
                        console.error('Error update Cart :>> ', error);
                    }
                }
                break;

            default:
                break;
        }
    }

    return ( 
        <React.Fragment>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                sx={{ backgroundColor: '#F8F8F8' }}
            >
                <AppBar sx={{ position: 'relative', backgroundColor: '#2C3335' }}>
                    <Toolbar>

                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>

                        <img src="https://goldencatch.fishing/images/logos/goldencatch-logo.svg" alt="Logo" style={{ width: 300, marginLeft: 4 }} />

                        <Typography sx={{
                            fontFamily: 'Montserrat, sans-serif',
                            fontSize: '25px',
                            marginLeft: '20px',
                            ml: 2,
                            flex: 1,
                            textTransform: 'uppercase'
                        }}>{l('Cart')}</Typography>

                        <Button disabled={basketRecoil?.basket?.totsum < 75}
                            sx={{
                                mr: 2,
                                "&.Mui-disabled": {
                                    color: "white",
                                }
                            }}
                            variant="contained"
                            autoFocus
                            color="success"
                            onClick={handleConfirm}
                        >
                            {l('Send')}
                        </Button>

                        <Button 
                        sx={{
                                mr: 2,
                                "&.Mui-disabled": {
                                    color: "white",
                                }
                            }}
                        disabled={!basketRecoil?.basket?.math?.length} 
                        variant="contained" 
                        autoFocus 
                        color="error" 
                        onClick={handleDelete}>
                            {l('Remove basket')}
                        </Button>
                    </Toolbar>
                </AppBar>
                <Box display="flex" flexDirection='column' sx={{ ml: 5, mr: 5, mt: 2 }} gap={2}>
                    <Box display="flex" gap={2}>
                        <Typography component="h2" variant="h5">
                            {l('Confirm the shipment of the Order')}
                        </Typography>

                        <Divider orientation="vertical" flexItem />

                        <Typography component="h6" variant="h6">
                            {l('Total')}:
                        </Typography>

                        <Typography display="flex" alignItems="center" component="h6" variant="h6">
                            <span style={{ color: "green" }}>
                                {basketRecoil?.basket?.totsum}{config.currencySign}
                            </span>
                        </Typography>

                        <Typography display="flex" alignItems="center" component="h6" variant="h6">
                            <ScaleIcon />
                            <span style={{ color: "#dc3545", marginLeft: 5 }}>
                                {basketRecoil?.basket?.totweight}{l('Kg')}
                            </span>
                        </Typography>

                        <Typography display="flex" alignItems="center" component="h6" variant="h6">
                            <WbIridescentIcon />
                            <span style={{ color: "#007bff", marginLeft: 5 }}>
                                {basketRecoil?.basket?.totvolume}{l('m³')}
                            </span>
                        </Typography>
                    </Box>

                    <Box>
                        <TableData
                            columns={columns}
                            rows={basketRecoil?.basket?.math}
                            cellBtnActionByType={handelAction}
                        />
                    </Box>

                    {basketRecoil?.basket?.totsum < config.orderlimit && <Alert severity="error">{l('The order amount must be at least ')+config.currencySign+config.orderlimit}</Alert>}

                    <TextField multiline rows={3} label={`${l("Your comment")}...`} variant="outlined" />
                </Box>
            </Dialog>
        </React.Fragment>
    );
}

export default BasketDialog;