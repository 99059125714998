const domen = window.location.hostname;
const { 
    REACT_APP_DOMEN_UKR, 
    REACT_APP_DOMEN_EUR, 
    REACT_APP_COMPNO_UKR, 
    REACT_APP_COMPNO_EUR,
    REACT_APP_SERVER_URL,
    REACT_APP_ORDERLIMIT_UKR,
    REACT_APP_ORDERLIMIT_EUR,
    REACT_APP_UKR_CUR_SIGN,
    REACT_APP_EUR_CUR_SIGN,
    REACT_APP_IS_CHECK_QUANT_UKR,
    REACT_APP_IS_CHECK_QUANT_EUR,
    REACT_APP_UKR_PRICE1_LABLEL,
    REACT_APP_UKR_PRICE2_LABLEL,
    REACT_APP_EUR_PRICE1_LABLEL,
    REACT_APP_EUR_PRICE2_LABLEL,
    REACT_APP_UKR_DETAIL_URL,
    REACT_APP_EUR_DETAIL_URL,
} = process.env;

let defaultLocale = '';
let allowedLocales = [];
let appCompno = 0;
let serverUrl = REACT_APP_SERVER_URL || `https://${domen}/api`;
let orderlimit = 0;
let currencySign = '';
let isCheckQuant = false;
let price1Label = '';
let price2Label = '';
let compDetailUrl = '';

switch (domen) {
    case REACT_APP_DOMEN_UKR:

        defaultLocale = 'ua';
        allowedLocales = ['ua', 'en', 'ru'];
        appCompno = REACT_APP_COMPNO_UKR;
        orderlimit = REACT_APP_ORDERLIMIT_UKR;
        currencySign = REACT_APP_UKR_CUR_SIGN;
        isCheckQuant = REACT_APP_IS_CHECK_QUANT_UKR;
        price1Label = REACT_APP_UKR_PRICE1_LABLEL;
        price2Label = REACT_APP_UKR_PRICE2_LABLEL;
        compDetailUrl = REACT_APP_UKR_DETAIL_URL;
        break;
    case REACT_APP_DOMEN_EUR:

        defaultLocale = 'en';
        allowedLocales = ['en'];
        appCompno = REACT_APP_COMPNO_EUR;
        orderlimit = REACT_APP_ORDERLIMIT_EUR;
        currencySign = REACT_APP_EUR_CUR_SIGN;
        isCheckQuant = REACT_APP_IS_CHECK_QUANT_EUR;
        price1Label = REACT_APP_EUR_PRICE1_LABLEL;
        price2Label = REACT_APP_EUR_PRICE2_LABLEL;
        compDetailUrl = REACT_APP_EUR_DETAIL_URL;
        break;
    default:
        
        defaultLocale = 'ua';
        allowedLocales = ['ua', 'en', 'ru'];
        appCompno = 1;
        orderlimit = 0;
        currencySign = '$';
        break;
}

export { defaultLocale, allowedLocales, appCompno, serverUrl, orderlimit, currencySign, isCheckQuant, price1Label, price2Label, compDetailUrl };
export const backendApiUrl = '';
export const dataFormat = 'HH:mm DD.MM.YYYY';