import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import CircleIcon from '@mui/icons-material/Circle';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/Error';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import moment from 'moment';
import CellType from '../../../constants/type';
import { dataFormat,currencySign } from '../../../config';
import useI18n from '../../../hooks/useI18n';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Box, TextField, Tooltip, Pagination, Select, MenuItem, Divider } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import { isCheckQuant } from '../../../config';

const handleInput = (event) => {
    const input = event?.target;
    input.value = input.value.replace(/[^0-9]/g, '');
};

function convertImageUrl(url) {
    const parts = url?.split('/');

    let fileName = parts?.pop();
    fileName = fileName?.split('.')[0];

    parts?.push('thumb', '800_' + fileName + '.webp');
    return parts?.join('/');
}

function setCell(params) {
    const { type, data, action, row, l, handleClickOpenImage, language, index } = params;
    const roundValue = 10000;
    const roundSum = 100;

    switch (type) {
        case CellType.STRING:
            return l(data);
        case CellType.NUMBER:
            return data;
        case CellType.DATE:
            return moment(data).format(dataFormat);
        case CellType.DATEONLY:
            return moment(data).format('DD-MM-YYYY');
        case CellType.BOOLEAN:
            if (data)
                return <CheckCircleIcon color='success' />
            else
                return <CheckCircleIcon color='disabled' />;
        case CellType.BOOLCONNECTED:
            if (data)
                return <WifiIcon color='success' />
            else
                return <WifiOffIcon color='disabled' />;
        case CellType.BOOLSTATUS:
            if (!data) return <CircleIcon color='error' />
            return <CircleIcon color={data} />
        case CellType.BOOLALARM:
            switch (data) {
                case 0:
                    return <ErrorIcon color='error' />
                case 1:
                    return <ErrorIcon color='warning' />
                case 2:
                    return <ErrorIcon color='success' />
                default:
                    return data;
            }
        case CellType.EDITABLE_NUMBER:
            return <>
                <TextField
                    id={`number_${index}`}
                    onInput={handleInput}
                    onChange={(event) => action ? action(event, CellType.EDITABLE_NUMBER, row)
                        : () => { console.warn('No action for this button') }}
                    sx={{
                        maxWidth: '80px',
                        '& .MuiInputBase-input': {
                            color: isCheckQuant === '1' ? (data < row?.FMINQTY && parseInt(data, 10) !== 0 ? 'red' : '#212121') : '#212121', //#212121
                        }
                    }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {

                            let indexNumber = index;

                            let nextNumber = document.getElementById(`number_${index + 1}`);

                            if (nextNumber) {

                                let disabled = nextNumber?.disabled;

                                while (disabled) {
                                    ++indexNumber;
                                    nextNumber = document.getElementById(`number_${indexNumber}`);
                                    disabled = nextNumber.disabled;   
                                }

                                nextNumber?.focus();
                            }
                            else {
                                const firstNumber = document.getElementById(`number_0`);
                                if (firstNumber) firstNumber?.focus();
                            }
                        }
                    }}
                    defaultValue={data}
                    disabled={isCheckQuant === '1' ? !row?.FMINQTY : false}
                    size='small'
                    type='number' />
                {isCheckQuant === '1' ?
                    <Tooltip title={l('Minimum order quantity')}>
                        <Box margin={1} display={'flex'} alignItems={'center'}>
                            <Box fontSize={'12px'} >{row?.FMINQTY}</Box>
                        </Box>
                    </Tooltip> : ''
                }  
            </>;
        case CellType.ACTIONS:
            return <IconButton
                aria-label="delete"
                onClick={
                    (event) => action ? action(event, CellType.ACTIONS, row)
                        : () => { console.warn('No action for this button') }}
            >
                <DeleteIcon />
            </IconButton>

        case CellType.IMG:
            return <img
                src={convertImageUrl(data)}
                onClick={() => handleClickOpenImage ? handleClickOpenImage(data)
                    : () => { console.warn('No action for this button') }}
                alt=''
                style={{ maxHeight: '50px', maxWidth: "50px", cursor: 'pointer' }} />

        case CellType.PRODUCT_NAME:
            return <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                <Box>
                    <Box>{language === 'ru' ? row.FNAMERUS : row.FNAME || row?.SPEC}</Box>
                    <Box display={'flex'} justifyContent={'end'}>
                        <Box color="#dc3545" display={'flex'} fontSize={'11px'} margin={'auto'}>
                            <Box>{row.FWEIGHT || 0}</Box>&nbsp;{l('Kg')}
                        </Box>
                        <Box color="#007bff" display={'flex'} fontSize={'11px'} margin={'auto'}>
                            <Box>{row.FVOLUME || 0}</Box>&nbsp;{l('m³')}
                        </Box>
                    </Box>
                </Box>
                <Box className='box-detail'>
                    <Typography
                        variant='p'
                        onClick={
                            (event) => action ? action(event, CellType.PRODUCT_NAME, row)
                                : () => { console.warn('No action for this button') }}
                        className='hover-d detail'
                    >{l('Details')} &nbsp;

                    </Typography>
                </Box>
            </Box>

        case CellType.PRODUCT_SUM:
            return <Box>
                <Box>
                    {data}&nbsp;{currencySign}
                </Box>
                <Box color="#dc3545">
                    {row.SUMFWEIGHT}&nbsp;{l('Kg')}
                </Box>
                <Box color="#007bff">
                    {row.SUMFVOLUME}&nbsp;{l('m³')}
                </Box>

            </Box>

        case CellType.PRISE_WITH_DISCOUNT:

            return <Box display="flex" alignItems="center" gap="10px">
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    width="35px"
                >
                    {row.FDISCOUNT !== 0 && row.FDISCOUNT ? (
                        <>
                            <Box color="gray" sx={{ textDecoration: 'line-through' }}>
                                {Math.round(data * roundSum) / roundSum}
                            </Box>
                            <Box>
                                {Math.round((data / 100 * (100 - row.FDISCOUNT)) * roundSum) / roundSum}
                            </Box>
                        </>
                    ) : (
                        <Box>
                            {Math.round(data * roundValue) / roundValue}
                        </Box>
                    )}
                </Box>
                {row.FDISCOUNT !== 0 && row.FDISCOUNT ? (
                    <Tooltip title={l('Discount')}>
                        <Box className="discount-label">
                            -{row.FDISCOUNT}%
                        </Box>
                    </Tooltip>
                ) : ''
                }
            </Box>
            case CellType.FINSTOCK:

            return <Box sx={{color: data === 'Немає в наявності' ? 'red' : '#212121' }}>
                {l(data)}
            </Box>

        default:
            return data;
    }

}

const groupByCategoryAndBrand = (products) => {

    const groupedData = {};

    if (!products) {
        return groupedData;
    }

    products.forEach(product => {
        const { LEVEL1, LEVEL2 } = product;

        if (!groupedData[LEVEL1]) {
            groupedData[LEVEL1] = {};
        }

        if (!groupedData[LEVEL1][LEVEL2]) {
            groupedData[LEVEL1][LEVEL2] = [];
        }

        groupedData[LEVEL1][LEVEL2].push(product);
    });

    return groupedData;
};

export default function TableData(prop, config) {
    let { 
        columns, 
        rows, 
        setClickRow, 
        isPointCursor, 
        cellBtnActionByType, 
        isPriceList = false, 
        ownPagination = false, 
        onPageChange, 
        onRowsPerPageChange,
    } = prop;



    const [page, setPage] = React.useState(0);
    const [openImage, setOpenImage] = React.useState(false);
    const [image, setImage] = React.useState('');
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [groupedData, setGroupedData] = React.useState({});
    const { l, language } = useI18n();

    const paginationData = (newPage) => {

        if (isPriceList)
            setGroupedData(
                groupByCategoryAndBrand(
                    rows?.slice(
                        newPage * rowsPerPage,
                        newPage * rowsPerPage + rowsPerPage)
                )
            );
    };

    React.useEffect(() => {
        paginationData(0)
        setPage(ownPagination?.page || 0);
    }, [rows]);

    const handleChangePage = (event, newPage) => {
        window.scrollTo(0, 0);
        setPage(newPage - 1);
        paginationData(newPage);
    };

    const handleClickOpenImage = (image) => {
        setImage(image);
        setOpenImage(true);
    };

    const handleCloseImage = () => {
        setOpenImage(false);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event?.target.value);
    };

    const paginationInfo = () => {
        const currentPage = page || 1;

        const startItem = (currentPage - 1) * (ownPagination?.pageSize : 0) + 1;
        const endItem = Math.min(currentPage * (ownPagination?.pageSize : 0), ownPagination?.totalItems || 0);

        return <Typography variant='p'>{`${l('Shown')} ${startItem}-${endItem} ${l('of')} ${ownPagination?.totalItems || 0}`}</Typography>;
    };

    const paginationInfoOff = () => {
        const currentPage = page;

        const startItem = (currentPage) * (rowsPerPage || 0) + 1;
        const endItem = Math.min((currentPage + 1) * (rowsPerPage || 0), rows?.length || 0);

        return <Typography variant='p'>{`${l('Shown')} ${startItem}-${endItem} ${l('of')} ${rows?.length || 0}`}</Typography>;
    };

    const countOfPages = (rows ? rows?.length : 0) / rowsPerPage;

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            {ownPagination ? <>
                <Box marginLeft={1} marginTop={1} marginBottom={1} >
                    {`${l('Rows per page')}:`}
                    <Select
                        variant="standard"
                        sx={{ marginLeft: 1, height: '25px' }}
                        value={ownPagination?.pageSize || 100}
                        onChange={onRowsPerPageChange}
                    >
                        {(ownPagination.rowsPerPageOptions.map((option) =>
                            <MenuItem key={option} value={option}>{option}</MenuItem>
                        ))}
                    </Select>
                </Box>

                <Divider />
            </> : ''}

            <TableContainer sx={{ height: '100%' }}>
                <Table sx={{ width: '100%' }} stickyHeader aria-label="sticky table">

                    <TableHead>
                        <TableRow>
                            {columns ? columns?.map((column, i) => (
                                <TableCell
                                    sx={{ padding: 1 }}
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                                >
                                    {column.label}
                                </TableCell>
                            )) :
                                <TableCell>
                                    <Skeleton variant="rounded" width={60} height={60} />
                                </TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isPriceList ? <>{rows ?
                            Object.keys(groupedData).map((category, i) => {

                                const brand = groupedData[category];

                                return (
                                    <React.Fragment key={category + i}>
                                        <TableRow>
                                            <TableCell colSpan={8} sx={{ padding: 1 }}>
                                                <Box>
                                                    <Typography color="GrayText" variant="h6">{category}</Typography>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                        {
                                            Object.keys(brand).map((brandName, j) => {

                                                const data = brand[brandName];

                                                return (
                                                    <React.Fragment key={brandName + j}>
                                                        <TableRow >
                                                            <TableCell></TableCell>
                                                            <TableCell colSpan={7} sx={{ padding: 1 }}>
                                                                <Box>
                                                                    <Typography fontWeight="bold" fontStyle="italic" variant="h6">{brandName}</Typography>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                        {
                                                            data.map((row, index) => {
                                                                return (
                                                                    <TableRow
                                                                        hover={isPointCursor}
                                                                        sx={{ cursor: isPointCursor ? 'pointer' : 'default' }}
                                                                        onClick={() => {
                                                                            if (setClickRow) {
                                                                                setClickRow(row);
                                                                            }
                                                                        }
                                                                        }
                                                                        role="checkbox"
                                                                        tabIndex={-1}
                                                                        key={row.id || index}
                                                                    >
                                                                        {columns.map((column) => {

                                                                            const value = row[column.id];
                                                                            return (
                                                                                <TableCell sx={{ padding: 1 }} key={`${row.id}-${column.id}`}>
                                                                                    <Box
                                                                                        justifyContent={column?.align}
                                                                                        sx={{ display: 'flex' }}
                                                                                        alignItems={'center'}>
                                                                                        {setCell({
                                                                                            type: column.type,
                                                                                            data: value,
                                                                                            action: cellBtnActionByType,
                                                                                            row,
                                                                                            l,
                                                                                            handleClickOpenImage,
                                                                                            language,
                                                                                            index,
                                                                                        })}
                                                                                    </Box>
                                                                                </TableCell>);
                                                                        })}
                                                                    </TableRow>
                                                                );
                                                            })
                                                        }
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </React.Fragment>);
                            })
                            :
                            Array(3).fill(0).map((_, index) => (<TableRow key={index}>
                                {
                                    columns.map((_, j) =>
                                    (<TableCell key={`${index}-${j}`} sx={{ margin: '10px 0px' }}>
                                        <Skeleton variant="rounded" width={"100%"} height={20} />
                                    </TableCell>))
                                }
                            </TableRow>))
                        }</>
                            :
                            <>
                                {rows ? rows
                                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    ?.map((row, i) => {
                                        return (
                                            <TableRow
                                                hover={isPointCursor}
                                                sx={{ cursor: isPointCursor ? 'pointer' : 'default' }}
                                                onClick={() => {
                                                    if (setClickRow) {
                                                        setClickRow(row);
                                                    }
                                                }
                                                }
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row.id || i}>
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell sx={{ padding: 1 }} key={`${row.id}-${column.id}`}>
                                                            <Box justifyContent={column?.align} sx={{ display: 'flex' }} alignItems={'center'}>
                                                                {setCell({ type: column.type, data: value, action: cellBtnActionByType, row, l, handleClickOpenImage, language, index: i })}
                                                            </Box>
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    }) :
                                    Array(3).fill(0).map((_, i) => (<TableRow key={i}>
                                        {
                                            columns.map((_, j) =>
                                            (<TableCell key={`${i}-${j}`} sx={{ margin: '10px 0px' }}>
                                                <Skeleton variant="rounded" width={"100%"} height={20} />
                                            </TableCell>))
                                        }
                                    </TableRow>))

                                }

                            </>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {ownPagination ?
                <Box display='flex' flexWrap='wrap' justifyContent='space-between' alignItems='center' padding='10px 0'>
                    <Box marginLeft={1}>{paginationInfo()}</Box>
                    <Pagination
                        count={ownPagination?.totalPages || 0}
                        shape="rounded"
                        onChange={onPageChange}
                    />
                    <Box width='20%'></Box>
                </Box> : 
                    (countOfPages > 1) ?
                    <Box display='flex' flexWrap='wrap' justifyContent='space-between' alignItems='center' padding='10px 0'>
                    <Box marginLeft={1}>{paginationInfoOff()}</Box>
                    <Pagination
                        count={countOfPages}
                        shape="rounded"
                        onChange={handleChangePage}
                    />
                    <Box width='20%'></Box>
                </Box>: ''  
            }
            <Dialog
                open={openImage}
                onClose={handleCloseImage}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogActions>
                    <IconButton onClick={handleCloseImage}><ClearIcon /></IconButton>
                </DialogActions>
                <DialogContent>
                    <img src={image} alt='' style={{ width: '100%' }} />
                </DialogContent>

            </Dialog>
        </Paper>
    );
}

/* <TablePagination
            rowsPerPageOptions={ownPagination.rowsPerPageOptions}
            component="div"
            count={ownPagination.totalItems}
            rowsPerPage={ownPagination.pageSize}
            page={ownPagination.page}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            labelRowsPerPage={`${l('Rows per page')}:`}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${l('of')} ${count}`}
        /> */